module.exports = {
  getStyleObject: (
    { marginBottom = "small", color },
    { bottomOffset } = {}
  ) => {
    let marginBottomVal;

    switch (marginBottom) {
      case "none":
        marginBottomVal = "0vh";
        break;
      case "small":
        marginBottomVal = "10vh";
        break;
      case "medium":
        marginBottomVal = "20vh";
        break;
      case "large":
        marginBottomVal = "30vh";
        break;
    }

    if (bottomOffset) {
      return { marginBottom: `calc(${marginBottomVal} + ${bottomOffset})` };
    }

    return { color, marginBottom: marginBottomVal };
  },
};
